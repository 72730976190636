<template>
  <div class="bg-white">
    <div class="flex items-center justify-between px-4 py-4 mt-2 bg-white">
      <TitlePlus
        :title="
          $t('components.riderDetailsManagement.diveIntoTab.pass.headline')
        "
        :hide-plus="true"
      />
      <DateRangePicker
        @apply-date="onApplyFilterDateRange"
        @calcel-date="onApplyFilterDateRange"
      />
    </div>

    <div
      class="flex flex-col px-4 mt-5 space-y-5 lg:items-center lg:space-y-0 lg:space-x-5 lg:flex-row mb-10"
    >
      <SummaryCard
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.pass.summary.totalPasses'
          )
        "
        :value="getTotalPass"
        variant="gray"
      />
    </div>

    <FSTable
      :fst-id="`riderPass`"
      :endpoint="
        `/dashboard/riders/${$route.params.id}/pass-subscription-records/`
      "
      :headers="tableHeaders"
      @meta="(e) => (indexMetaData = e)"
    >
      <template #default="{ data }">
        <FSTableRow
          v-for="(item, itemIndex) in data"
          :key="itemIndex"
          text-fallback-always
        >
          <FSTableRowItem
            :text="item.pass_id"
            :truncate="-5"
            :to="{ name: 'PassDetails', params: { id: item.pass_id } }"
          />

          <FSTableRowItem
            :text="getFormattedDate(item.pass_start_date, 'D MMM, YYYY')"
          />

          <FSTableRowItem
            :text="getFormattedDate(item.pass_end_date, 'D MMM, YYYY')"
          />

          <FSTableRowItem :text="item.pass_type" />

          <FSTableRowItem :text="`${symbol} ${item.spent_amount_from_pass}`" />

          <FSTableRowItem :text="`${symbol} ${item.pass_rate}`" />

          <FSTableRowItem>
            <x-status
              :text="item.status"
              :variant="getItemVariant(item.status)"
            />
          </FSTableRowItem>
          <!-- <FSTableRowItem>
            <oto-eye-icon v-tooltip.bottom="'View & Download Invoice'" />
          </FSTableRowItem>

          <FSTableRowItem>
            <div
              class="refund-action"
              :class="item.is_refunded ? 'refunded' : 'refund'"
              @click="showRefundPopup(item)"
            >
              {{ item.is_refunded ? 'Refunded' : 'Refund' }}
            </div>
          </FSTableRowItem> -->
        </FSTableRow>
      </template>
    </FSTable>
  </div>
</template>

<script>
import DateRangePicker from '@/components/picker/date-range/DateRangePicker.vue'
// import OtoEyeIcon from '@/components/ui/OtoEyeIcon'
// import RefundPopup from '@/views/transactions/RefundPopup.vue'
import SummaryCard from '@/components/cards/SummaryCard'
import { getFormattedDate } from '@/utils/datetime'
import XStatus from '@/components/badge/XStatus'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'

export default {
  name: 'ViewRiderUserPass',
  components: {
    DateRangePicker,
    // OtoEyeIcon,
    // RefundPopup,
    SummaryCard,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    XStatus,
    TitlePlus: () => import('@/components/ui/TitlePlus'),
  },
  data() {
    return {
      symbol: '$',
      indexMetaData: {
        summary: {
          total: '0',
          balance: '0.00',
          current_pass: null,
        },
        count: {
          total: 0,
        },
      },
      tableHeaders: [
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.passId'
          ),
          width: '10%',
          sort: 'pass_id',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.startDate'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.endDate'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.passType'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.spentAmount'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.passDeposit'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.status'
          ),
          width: '10%',
          sort: null,
        },
      ],
    }
  },
  computed: {
    getTotalPass() {
      return `${this.indexMetaData?.summary?.total}`
    },
    getPassBalance() {
      return `${this.symbol} ${this.indexMetaData?.summary?.balance}`
    },
  },
  mounted() {
    this.symbol = this?.$org?.default_currency?.symbol || '$'
  },
  methods: {
    onApplyFilterDateRange,
    getFormattedDate,
    getItemVariant(status) {
      console.log('item-status', status)
      if (status === 'Active') return 'green'
      if (status === 'Inactive') return 'gray'
      return 'red'
    },
  },
}
</script>
